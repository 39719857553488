import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наши услуги | Изучите наши услуги в Greenwood Fairways
			</title>
			<meta name={"description"} content={"Повысьте уровень своей игры, обогатите свой опыт"} />
			<meta property={"og:title"} content={"Наши услуги | Изучите наши услуги в Greenwood Fairways"} />
			<meta property={"og:description"} content={"Повысьте уровень своей игры, обогатите свой опыт"} />
			<meta property={"og:image"} content={"https://zuniqa.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zuniqa.com/img/golf-club-5093516.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zuniqa.com/img/golf-club-5093516.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zuniqa.com/img/golf-club-5093516.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zuniqa.com/img/golf-club-5093516.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zuniqa.com/img/golf-club-5093516.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zuniqa.com/img/golf-club-5093516.png"} />
		</Helmet>
		<Components.Header />
		<Section text-align="center" color="--light" padding="80px 0" sm-padding="40px 0">
			<Text
				as="h1"
				font="--headline1"
				color="--primary"
				md-font="--headline2"
				margin="10px 0 0 0"
			>
				Премьерный опыт игры в гольф
			</Text>
			<Text
				as="p"
				font="--lead"
				color="--dark"
				margin="40px 0 20px 0"
				text-align="left"
			>
				В основе Greenwood Fairways лежит наше стремление предложить исключительные условия для игры в гольф. Наше поле создано для того, чтобы бросить вызов игрокам любого уровня, а также обеспечить живописный фон, который улучшает каждый раунд.
<br/><br/>Чемпионское поле: Откройте для себя 18 лунок с тщательно ухоженными фервеями и зеленью, каждая из которых создана, чтобы бросать вызов и радовать.
<br/><br/>Тренировочные площадки: Совершенствуйте свой замах на нашем драйвинг-рейндже, полях для гольфа и площадках для чиппинга - все они созданы для того, чтобы имитировать условия поля.

			</Text>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="center"
					md-text-align="center"
					sm-text-align="left"
				>
					Наши услуги
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Обучение и совершенствование
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Повысьте уровень своей игры с помощью наших комплексных программ обучения, рассчитанных на гольфистов любого уровня подготовки.
					<br/><br/>Частные уроки: Работайте один на один с нашими профессионалами, сертифицированными PGA, чтобы отточить свою технику и стратегию.
					<br/><br/>Клиники и семинары: Присоединяйтесь к групповым занятиям, посвященным различным аспектам игры, от навыков короткой игры до психологических аспектов гольфа.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Клубный дом и удобства
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Наш гостеприимный клубный дом - идеальное место для отдыха после раунда. В нем есть целый ряд удобств, разработанных с учетом вашего комфорта.
					<br/><br/>
Магазин Pro Shop: Оборудован новейшим снаряжением и одеждой, чтобы вы могли играть как можно лучше.
					<br/><br/>
Столовая и лаунж: Попробуйте наше меню, разработанное шеф-поваром, в непринужденной обстановке, идеальной для общения с членами клуба и гостями.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Мероприятия и турниры
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Greenwood Fairways - это не просто место для игры в гольф, это целое сообщество. В течение года мы проводим различные мероприятия и турниры для всех уровней игры.
					<br/><br/>
Турниры для членов клуба: Участвуйте в дружеских, соревновательных мероприятиях, призванных сплотить наше сообщество.
<br/><br/>
Социальные мероприятия: В нашем календаре множество возможностей для общения с коллегами - от семейных праздников до танцев.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Инициативы в области устойчивого развития
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Наша забота об окружающей среде находит отражение в каждом аспекте нашей деятельности, начиная с обслуживания поля и заканчивая удобствами в клубном доме.
					<br/><br/>
Экологически безопасное обслуживание поля: Использование экологичных методов для того, чтобы наше поле оставалось зеленым оазисом.
<br/><br/>
Усилия по сохранению природы: Участие в инициативах, направленных на сохранение природной красоты наших окрестностей для будущих поколений.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Начните свое путешествие
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Ощутите вершину совершенства игры в гольф в Greenwood Fairways. Свяжитесь с нами, чтобы узнать больше о наших услугах и о том, как вы можете стать частью этого замечательного сообщества. Ваша история, ваше наследие начинаются здесь.
				</Text>
				<Link
					href="/contacts"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Связаться с нами
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});